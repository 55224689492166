.autocomplete-suggestions {
	border:     1px solid #999999;
	background: #FFFFFF;
	overflow:   auto;

	strong {
		font-weight: normal;
		color:       #3399FF;
	}
}

.autocomplete-suggestion {
	padding:     2px 5px;
	white-space: nowrap;
	overflow:    hidden;
}

.autocomplete-selected {
	background: #F0F0F0;
}