#homepage-slider > .item > img {
	display: block;
	width:   100%;
	height:  auto;
}

.owl-caption {
	position: absolute;
	bottom:   0;
	width:    100%;
}

.owl-caption > h2 {
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.8);
	color:            #FFFFFF;
	padding:          5px 0 5px 0;
}