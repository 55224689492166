body,
h1, h2, h3, h4, h5, h6 {
	font-family: 'Open Sans', sans-serif;
}

blockquote > p {
	font-size:   0.85em;
	line-height: 1.6;
}

.page-header,
.page-header > h1 {
	margin-top: 0;
}