a.topic-link {
	display:          block;
	padding:          10px 0 10px 0;
	background-color: $primary-colour;
	color:            #EEEEEE;
	border-bottom:    2px #FFFFFF solid;

	&:hover {
		text-decoration:  none;
		background-color: $secondary-colour;
		box-shadow:       inset -20px -20px 200px 10px #0B87B5;
		color:            #FFFFFF;

		h3 {
			font-weight: 400;
		}
	}

	h3 {
		margin:      0 20px 0 20px;
		font-weight: 300;
	}
}