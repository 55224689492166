.navbar,
.navbar-default {
	margin-bottom:    0;
	border:           0;
	border-radius:    0;
	background-color: $primary-colour;
	background-image: inherit;
	padding-top:      17px;
	padding-bottom:   17px;
	transition:       all 0.5s linear;
}

.navbar-brand {
	img {
		margin-top:   -15px;
		margin-right: 15px;
		margin-left:  10px;
		height:       70px;
	}
}

.navbar-toggle {
	&:focus,
	&:hover {
		background-color: #445567 !important;
	}
}

#main-nav {
	> .nav {
		> li {
			> a {
				font-size: 1.35em;
				color:     #FFFFFF !important;

				&:hover {
					background-color: $secondary-colour !important;
				}
			}
		}

		> li.active {
			> a {
				background: $secondary-colour none !important;
			}
		}

		li.open {
			> a {
				background-color: #344353 !important;
			}

			ul {
				li {
					a {
						&:hover {
							background-color: #344353 !important;
						}

						@media (min-width: 768px) {
							color: #333333 !important;
						}
					}
				}
				li.active {
					a {
						@media (min-width: 768px) {
							color: #FFFFFF !important;
						}
					}
				}
			}
		}
	}
}

@keyframes blinker {
	0% {
		background-color: #004085;
	}
	50% {
		background-color: #014C9D;
	}
	100% {
		background-color: #004085;
	}
}
