footer {
	padding-top:      40px;
	padding-bottom:   40px;
	text-align:       center;
	background-color: $primary-colour;
	color:            #FFFFFF;

	a,
	a:hover,
	a:active {
		color: #FFFFFF;
	}
}

.fb-link:hover {
	text-decoration: none;
	color:           #3B5998;
}

.yt-link:hover {
	text-decoration: none;
	color:           #CC181E;
}

.tw-link:hover {
	text-decoration: none;
	color:           #00ACEE;
}

.gp-link:hover {
	text-decoration: none;
	color:           #DC4A38;
}

.ig-link:hover {
	text-decoration: none;
	color:           #593225;
}

@media (min-width: 768px) {
	.footer-about {
		text-align: left;
	}

	.footer-created {
		text-align: right;
	}
}