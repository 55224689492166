.banner-container {
	background:              url(/img/banner.png) no-repeat;
	-webkit-background-size: cover;
	-moz-background-size:    cover;
	background-size:         cover;
	height:                  500px;
	display:                 block;
	text-align:              center;

	@media screen and (max-width: 767px) {
		height: 400px;
	}
}

.banner {
	display: inline-block;
	margin:  5px auto;

	@media screen and (max-width: 767px) {
		margin-top: 30px;
	}
}

.homepage-title {
	color:                     #000000;
	-webkit-text-fill-color:   #FFFFFF;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #000000;
	font-size:                 3em;
	font-family:               'Raleway', sans-serif !important;
	font-weight:               900;
	text-shadow:               4px 4px 15px #000000;
	margin-top:                5px;

	@media screen and (max-width: 767px) {
		font-size: 4em;
	}
}

h2.homepage-tagline {
	color:                     #FFFFFF;
	-webkit-text-fill-color:   #F0F0F0;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #000000;
	font-size:                 3.5em;
	font-family:               'Raleway', sans-serif;
	font-weight:               900;
	text-shadow:               4px 4px 15px #000000;
	margin-top:                10px;

	@media screen and (max-width: 767px) {
		font-size: 2em;
	}

	> a {
		color: #24CEF0 !important;
	}
}

p.homepage-tagline {
	color:                     #FFFFFF;
	-webkit-text-fill-color:   #F0F0F0;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #000000;
	font-family:               'Raleway', sans-serif;
	font-size:                 1.5em;
}

.highlights {
	margin-top:    60px;
	margin-bottom: 40px;

	span {
		i.fa-circle {
			color: $primary-colour;
		}
	}

	p {
		font-size: 1.2em;
	}
}

.homepage-video-wrapper {
	margin: 60px 0 60px 0;
}

.secondary-container {
	background-color: #30AEDD;
	color:            #FFFFFF;
	margin-top:       20px;
}

.external-links {
	margin-top:    60px;
	margin-bottom: 60px;
}

.mce_inline_error {
	border-color: #A94442;
	box-shadow:   inset 0 1px 1px rgba(0, 0, 0, .075);
}