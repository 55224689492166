.video-wrapper {
	margin:         0 auto 0 auto;
	position:       relative;
	padding-bottom: 56.25%;
	overflow:       hidden;

	iframe,
	object,
	embed {
		position: absolute;
		top:      0;
		left:     0;
		width:    100%;
		height:   100%;
	}
}