.vertical-middle,
.vertical-middle > td,
.vertical-middle > th {
	vertical-align: middle !important;
}

a.no-change {
	color: inherit;
}

.display-inline-block {
	display: inline-block !important;
}

.no-opacity {
	opacity: 0;
}