.btn-register {
	background-color: $primary-colour;
	color:            #FFFFFF;

	&:hover {
		background-color: darken($primary-colour, 10);
		color:            #FFFFFF;
	}
}

.btn-google {
	background-color: #DD4B39;
	color:            #FFFFFF;

	&:hover {
		background-color: darken(#DD4B39, 5);
		color:            darken(#FFFFFF, 5);
	}
}

.btn-facebook {
	background-color: #3B5998;
	color:            #FFFFFF;

	&:hover {
		background-color: darken(#3B5998, 5);
		color:            darken(#FFFFFF, 5);
	}
}