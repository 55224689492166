.checklist-videos {
	padding: 0;
	margin:  0;

	li {
		display: inline;
	}

	> li + li:before {
		display:       inline;
		content:       "\0026";
		padding-right: 5px;
	}
}

.criteria-container p {
	margin: 0 !important;
}