// Fonts
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);
@import url(http://fonts.googleapis.com/css?family=Raleway:900);
// Helpers
@import "imports/variables";
@import "imports/helpers";
// Layout
@import "imports/navbar";
@import "imports/search";
@import "imports/slider";
@import "imports/footer";
@import "imports/font";
@import "imports/buttons";
// Sections
@import "imports/homepage";
@import "imports/videos";
@import "imports/ad";
@import "imports/social";
@import "imports/topics";
@import "imports/checklists";
@import "imports/auto-complete";

body {
	background-color: #F1F1F1;
}

.content > .container {
	padding-top:        20px;
	padding-bottom:     20px;
	background-color:   #FFFFFF;
	-webkit-box-shadow: rgba(0, 0, 0, 0.0980392) 0px 1px 2px 0px;
}

.vote-message {
	background-color: #F44336;
	color:            #FFFFFF;
	padding:          8px;
	text-align:       center;
	position:         fixed;
	top:              0;
	z-index:          1000;
	width:            100%;
	font-size:        1.1em;

	> a {
		color: #57FFFF;
	}
}